@import '~antd/dist/antd.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div,
p {
  margin: 0;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.container-inner {
  background-color: #fff;
  margin: 10px 0 0 10px;
  min-height: calc(100vh - 66px);
  padding: 20px;
}
.action {
  color: #38f;
  cursor: pointer;
}
.text_disabled {
  color: #b5b5b5;
  cursor: no-drop;
}
.search-box {
  padding: 20px 15px 10px 15px;
  background: #f8f8f8;
  margin: 0 0 20px 0;
}
.search-box .form-box .ant-form-item-label {
  width: 130px;
}
.search-box .submit-box {
  padding: 0 0 0 130px;
  margin-bottom: 10px;
}
.search-box .submit-box .row-submit {
  margin-right: 10px;
  line-height: 32px;
}
