.container{
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}
.aside{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
}
.first-route{
  width: 92px;
  background-color: #273543;
  height: 100%;
}
.second-route{
  width: 132px;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid #ebedf0;
  z-index: 1;
  position: relative;
}
.shared-logo{
  background-image: url(../images/logo.png);
  width: 92px;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 35%;
  position: relative;
  text-align: center;
}
.exit{
  height: 50px;
  width: 40px;
  margin: 0 auto;
  cursor: pointer;
}
.boxDetail{
  margin-left: 224px;
  width: 100%;
  background: #f0f2f5;
  position: relative;
}
.boxDetailBig{
  margin-left: 92px;
  width: 100%;
  background: #f0f2f5;
  position: relative;
}
.active{
  color: #aff;
}
.active-second{
  color: #3773da;
}
.active-thrid{
  color: #3773da;
}
.first-menu .buttonNomal{
  outline: none;
  border: none;
  background-color: #273543;
  color: #c8c9cc;
  width: 92px;
  height: 40px;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.first-menu .buttonNomal:hover{
  color: #fff;
  background-color: #434e6c;
}
.first-route .active .buttonNomal{
  color: #ffffff;
  background-color: #38f;
}
.second-menu{
  margin-top: 10px;
  text-align: center;
}
.second-menu .buttonNomal{
  outline: none;
  border: none;
  background-color: #fff;
  color: #323233;
  width: 108px;
  height: 30px;
  text-align: left;
  cursor: pointer;
  padding-left: 20px;
}
.second-menu .buttonNomal:hover{
  color: #3773da;
}
.thrid-menu{
  margin-top: 5px;
  text-align: center;
}
.thrid-menu .buttonNomal{
  outline: none;
  border: none;
  background-color: #fff;
  color: #323233;
  width: 108px;
  height: 30px;
  text-align: left;
  cursor: pointer;
  padding-left: 30px;
}
.thrid-menu .buttonNomal:hover{
  color: #3773da;
}
.second-route .active-second .buttonNomal{
  color: #323233;
  background-color: #ebedf0;
}
.second-route .active-thrid .buttonNomal{
  color: #323233;
  background-color: #ebedf0;
}
.imgIcon{
  width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
}
.title-top{
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 56px;
  -moz-box-flex: 0;
  flex: 0 0 56px;
  padding-left: 28px;
  width: 100%;
  height: 56px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #ebedf0;
  font-size: 14px;
  color: #323233;
  line-height: 56px;
  font-weight: 500;
}
.second-group-title{
  position: relative;
  cursor: pointer;
  width: 131px;
  min-height: 30px;
  line-height: 30px;
  padding: 5px 0 0 32px;
  color: #323233;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  margin: 0;
}
.arrow-icon{
  display: inline-block;
  width: 5px;
  height: 5px;
  border: solid #878787;
  border-width: 1px 1px 0 0;
  background: none;
  position: absolute;
  top: 16px;
  left: 20px;
  -webkit-transform: scale(1.25) rotate(135deg);
  -moz-transform: scale(1.25) rotate(135deg);
  transform: scale(1.25) rotate(135deg);
}
.close-icon{
  .arrow-icon{
    animation: close 0.2s;
    animation-fill-mode:forwards;
  }
  .animal-box{
    max-height: 0;
    overflow: hidden;
    animation: heightClose 0.2s;
    animation-fill-mode:forwards;
  }
}
.open-icon{
  .arrow-icon{
    animation: opens 0.2s;
    animation-fill-mode:forwards;
  }
  .animal-box{
    max-height: 100vh;
    animation: heightOpen 0.2s;
    animation-fill-mode:forwards;
  }
}

@keyframes close
{
  from {
    -webkit-transform: scale(1.25) rotate(135deg);
    -moz-transform: scale(1.25) rotate(135deg);
    transform: scale(1.25) rotate(135deg)
  }
  to {
    -webkit-transform: scale(1.25) rotate(45deg);
    -moz-transform: scale(1.25) rotate(45deg);
    transform: scale(1.25) rotate(45deg);
  }
}
@keyframes opens
{
  from {
    -webkit-transform: scale(1.25) rotate(45deg);
    -moz-transform: scale(1.25) rotate(45deg);
    transform: scale(1.25) rotate(45deg);
  }
  to {
    -webkit-transform: scale(1.25) rotate(135deg);
    -moz-transform: scale(1.25) rotate(135deg);
    transform: scale(1.25) rotate(135deg)
  }
}

@keyframes heightClose
{
  from {
    max-height: 100vh;
  }
  to {
    max-height: 0;
    overflow: hidden;
  }
}
@keyframes heightOpen
{
  from {
    max-height: 0;
    overflow: hidden;
  }
  to {
    max-height: 100vh;
  }
}